@import "bootstrap/dist/css/bootstrap.min.css";
@import "react-alice-carousel/lib/alice-carousel.css";
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

:root {
  --color-grey-light: rgb(186, 186, 186);
  --border-thin-light: 1px solid var(--color-grey-light);
  --border-thin-dark: 1px solid rgba(0, 0, 0, 0.2);
  --border-black: 1px solid #000;
}

html {
  height: 100vh;
}

body {
  margin: 0;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-x: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  color: #000;
  text-decoration: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

button:hover {
  transition: all 300ms ease-in-out;
}

.menu-languages button {
  /* left: 8px; */
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  position: relative;
  left: -12px;
}

.menu-languages button::after,
.menu-languages button::before {
  margin-right: -0.6rem;
}

.filterBox {
  border-bottom: 1px solid #000;
}

.flag-select__options {
  opacity: 0.8;
  border-radius: 10px !important;
}

.filterBox input {
  border: none;
  outline: none;
}

.language-select-cont {
  display: flex;
  align-items: center;
}

.language-select-icon {
  background-color: #fff !important;
  border-radius: 50%;
  z-index: 3;
  position: absolute;
  /* left: 24px; */
  top: 20px;
  z-index: 3;
}

/* when cart = 0 */
.no-cart-badge {
  position: absolute;
  top: 16px;
  /* top: -10px; */
  /* right: 100px; */
}

/* when cart != 0 */
.cart-badge {
  position: absolute;
  top: 16px;
  /* right: 395px; */
}

/* when cart =0 */
.no-cart-badge-mobile {
  position: relative;
  top: -13px;
  left: 8px;
}

.mobile-nav {
  z-index: 2;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 3.5rem;
  display: flex;
  align-items: center;
}

.mobile-nav-dark {
  background-color: black;
}

.no-cart-badge-mobile-nav {
  position: relative;
  right: -40px;
  bottom: 10px;
}

.mobile-nav-light {
  background-color: white;
}

.mobile-nav-icons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem;
}

.mobile-icons {
  width: 2rem !important;
  height: 2rem !important;
  padding: 0.5rem;
}

.mobile-nav-dark .mobile-icons {
  color: white !important;
}

.mobile-nav-dark .mobile-icons:hover {
  border-bottom: 1px solid white;
  transition: all 300ms ease-in-out;
}

.mobile-icons:hover {
  border-bottom: 1px solid black;
  transition: all 300ms ease-in-out;
}

.not-found-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 2rem;
}

.not-found-text1 {
  padding: 1rem 0rem;
}

.not-found-text2 {
  font-size: 10rem !important;
}

.not-found-text4 p {
  margin-left: 0.5rem;
}

.go-to-btn {
  margin: 4rem 0rem;
}

.not-found-text4 {
  display: flex;
  align-items: center;
}

.f-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  height: 100px;
  margin: auto 0rem;
}

.fade-color {
  color: #575757e7;
}

/* Header start */
.h-dark {
  /* position: fixed; */
  /* width: 100%; */
  /* background-color: transparent; */
  /* filter: alpha(opacity=80); */
  background-color: #000;
}

.h-dark .language-select-icon {
  background-color: #000 !important;
  color: #fff !important;
}

.h-dark .menu-languages button {
  color: #fff;
  font-size: 0.9rem !important;
}

.h-dark-mobile {
  background-color: #000;
}

.header-form {
  width: 40% !important;
}

.header-form form {
  border-radius: 2rem !important;
}

.header-from-icon {
  position: absolute;
  z-index: 2;
  top: 8px;
  left: 5px;
}

.h-dark a {
  color: #fff;
}

.h-dark .nav-link:focus,
.h-dark .nav-link:hover {
  color: rgba(255, 250, 250, 0.8);
}

.h-light {
  background-color: transparent;
}

.h-light .menu-languages button {
  color: #000;
  font-size: 0.9rem !important;
}

.h-light a {
  color: #000;
}

.left-arrow {
  width: 2rem;
  background-color: white;
  border-radius: 50%;
}

.right-arrow {
  width: 2rem;
  background-color: white;
  border-radius: 50%;
}

.header-nav {
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.dropdown-menu {
  /* background-color: #fff; */
  /* --bs-dropdown-min-width: 30vw; */
  padding: 0rem 0.5rem;
  opacity: 0.85;
  border-radius: 1.5rem !important;
  height: fit-content;
  overflow: auto;
}

.small-dropdown {
  width: 22rem;
  overflow: scroll;
  height: 12rem;
  /* background-color: #000; */
  /* border: 1px solid #141313; */
  /* border-radius: 0.5rem; */
}

.small-dropdown .dropdown-box {
  margin-top: 1rem;
  padding: 0rem 0rem;
  text-align: left;
}

.small-dropdown .dropdown-box span {
  /* color: white; */
  padding: 0rem 1rem;
  font-size: 1rem;
}

.small-dropdown .dropdown-list-item {
  margin: 0rem;
  padding: 0rem 1.5rem;
  text-align: left;
  color: #575757 !important;
  font-size: 0.8rem;
}

.small-dropdown .speacials-container {
  border-radius: 1.2rem !important;
}

.small-dropdown .dropdown-box:hover {
  text-decoration: underline !important;
  background-color: transparent;
}

.small-dropdown .dropdown-list-item:hover {
  text-decoration: underline !important;
  background-color: transparent;
  color: #000 !important;
}

.dropdown-box {
  text-align: center;
  margin-top: 0.5rem;
  /* margin-bottom: 0.5rem; */
  padding: 0.5rem;
  /* margin-left: 1rem; */
  font-size: 1rem;
  text-align: center;
  /* font-weight: 500; */
  text-transform: uppercase;
}

.dropdown-box span {
  color: #000;
}

.dropdown-box:hover {
  background-color: #ced4da;
  border-radius: 2rem;
  transition: all 500ms ease-in-out;
  opacity: 0.7;
}

.dropdown-list-item {
  cursor: pointer;
  text-align: center;
  color: #000 !important;
}

.dropdown-list-item ul {
  list-style: none;
  padding: 0;
}

.dropdown-list-item:hover {
  transition: all 500ms ease-in-out;
  text-decoration: underline;
  background-color: transparent;
}

.dropdown-list-item:active,
.dropdown-list-item:focus {
  /* color: white !important; */
}

.speacials-container {
  border-top-right-radius: 1.2rem !important;
  border-bottom-right-radius: 1.2rem !important;
  background: #cf1949;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.speacials-text {
  color: white;
  text-transform: uppercase;
  font-weight: 600;
}

.dropdown-list-item > li:last-child {
  border: none;
}

.sec-2-body {
  margin-top: 2rem;
}

.orders-card-div {
  padding: 0.7rem;
  display: flex;
  /* align-items: center; */
  /* flex-direction: column; */
  width: 20rem;
}

.order-card {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.2rem;
}

.order-title {
  font-size: 1rem;
}

.all-orders {
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #efefef;
  margin: 0rem 2rem;
}

.all-orders-col {
  margin: 1rem;
}

.filter-order {
  display: flex;
  padding: 0rem 2rem;
  text-align: left;
  margin-top: 6rem;
  align-items: center;
  margin-bottom: 2rem;
  transition: all 300ms ease-in-out;
}

.form-select-filter {
  cursor: pointer;
  width: 8rem;
  margin-left: 2rem;
}

.manage-addre {
  width: 50%;
}

.manage-addre button {
  font-size: 0.9rem;
}

.all-orders-at {
  background-color: #efefef;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
  padding: 1rem 1rem;
  border-bottom: 1px solid rgb(169, 169, 169);
}

.installer {
  /* display: flex; */
  /* align-items: flex-end; */
  /* justify-content: flex-end; */
  border-bottom: 0.5px solid #e1e3e4;
  margin: 0.6rem 0rem;
  font-size: 0.9rem;
  font-weight: 600;
}

.installer-cont {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 10px;
  margin: 0.5rem 0rem !important;
  background-color: #ececec;
}

.installer-detail {
  font-size: 0.7rem;
}

.ship-to:hover {
  transition: all 300ms ease-in-out;
  opacity: 0.6;
  text-decoration: underline;
}

.orders-ship-to {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-pending {
  color: red;
  font-size: 1.2rem;
  text-transform: capitalize;
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.order-paid,
.order-delivered {
  font-size: 1.2rem;
  text-transform: capitalize;
}

.order-delivered {
  color: green;
}

.order-paid {
  color: #000;
}

.order-id {
  text-align: right;
  padding-right: 1.5rem;
}

.order-id span {
  font-style: italic;
}

.order-id-info {
  color: #8c8b8b;
}

.not-allowed-container {
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  /* width: 70%; */
  /* margin: auto; */
  margin-top: 5rem;
}

.not-allowed-div {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: #f7f7f7;
  box-shadow: 1px 1px 2px 0px rgb(17, 17, 18, 0.2);
}

.not-allowed-heading {
  display: flex;
  align-items: center;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.not-allowed-heading p {
  font-size: 3.2rem;
  font-weight: 600;
}

.err-code {
  margin: 0rem 0.3rem;
  padding: 0rem 0.5rem;
  background-color: #ced4da;
  border-radius: 4rem;
}

.not-allowed-body {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
}

.carousel-caption {
  /* width: 80%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: rgba(1, 1, 1, 0.5); */
  text-align: center;
  padding: 2rem;
}

.carousel-caption button {
  margin-top: 2rem;
}

.filters-txt {
  font-size: 2rem;
  font-weight: 600;
  /* margin-top: 2rem; */
}

/* this when checkout btn is visible */
.center-app-logo {
  margin-left: -1% !important;
  /* margin-left: -1rem !important; */
}

/* this when checkout btn is not visible */
.center-logo {
  margin-left: -10% !important;
  /* margin-left: -10rem !important; */
}

/* this when no btn*/
.app-logo {
  margin-left: -16% !important;
  /* margin-left: -14rem !important; */
}

.our-satisfied-customer-root {
  background-color: #fff;
  margin: 1rem;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 0.5rem;
}

.our-satisfied-customer-name {
  border-bottom: 0.5px solid #e9e7e2 !important;
}

.our-satisfied-customer-name p {
  font-size: 1.1rem;
  font-weight: 600;
  color: #000 !important;
  text-align: center;
}

.our-satisfied-customer-review-txt {
  margin: 0.3rem 0.1rem;
  color: #000 !important;
}

.our-satisfied-customer-review-txt::first-letter {
  padding-left: 2rem;
}

.our-satisfied-customer-lefticon {
  font-size: 1.5rem;
  color: #000 !important;
  display: flex;
  opacity: 0.7;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0.8rem 0.5rem;
}

.our-satisfied-customer-righticon {
  font-size: 1.5rem;
  display: flex;
  color: #000 !important;
  opacity: 0.7;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 0.8rem 0.5rem;
}

.latestnews-root {
  background-color: #f8f8f8;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.latestnews-root p {
  cursor: pointer;
  color: #000 !important;
  font-weight: 500;
}

.dropdown-div {
  display: flex;
}

.categories-txt {
  padding-top: 0.7rem;
  font-size: 1.2rem;
}

.app-logo {
  border-radius: 0.4rem;
}

.carousel-item img {
  width: 100%;
  /* height: 600px; */
  object-fit: cover;
}

.carousel-div {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* justify-content: space-between; */
}

.carousel-product-name {
  transition: all 500ms ease-in-out;
  font-size: 2rem;
  /* margin-left: -10rem; */
  margin-bottom: 2rem;
}

.carousel-product-price {
  transition: all 500ms ease-in-out;
  font-size: 1.5rem;
  text-decoration: line-through;
  margin-left: 1rem;
}

.carousel-product-updated {
  transition: all 500ms ease-in-out;
  font-size: 1.2rem;
}

.re-order-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0.5rem;
}

.all-addr-upper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6rem;
}

.all-addr-empty {
  margin-top: 3rem;
  text-align: center;
  font-weight: 500;
  text-decoration: underline;
}

@media only screen and (min-width: 500px) {
  #mobileshow {
    display: none !important;
  }
}

.total-amount {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0rem 2rem;
}

.all-orders-prod {
  padding: 0.8rem 0rem;
  width: 100%;
  display: flex;

  justify-content: space-between;
}

.ordered-at {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 0.8rem;
  opacity: 0.7;
}

.order-text {
  padding: 0rem 0rem;
  background-color: #f8f8f8;
  font-weight: 340;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}

.order-details-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recent-order-total {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
}

.recent-order-heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.6rem;
  border-bottom: 1px solid #bbbbbb;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.recent-order-img {
  margin-right: 0.4rem;
  padding: 0.5rem 0rem;
}

.recent-order-img img {
  width: 2.3rem;
  border-radius: 50%;
  border: 1px solid #ced4da;
}

.re-order-btn-all-order button {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .re-order-btn-all-order button:hover {
  background-color: transparent;
  color: #000;
} */

.re-order-btn-all-order svg {
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 0.3rem;
  margin-top: 0.1rem;
}

.re-order-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.all-orders-prod-cont {
  padding: 1rem;
  margin: 2rem 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.re-order-btn button:hover {
  transition: all 300ms ease-in-out;
  /* background-color: transparent;
  color: #000; */
}

.re-order-btn svg {
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 0.3rem;
  margin-top: 0.1rem;
}

.order-quantity {
  font-size: 0.8rem;
  opacity: 0.5;
}

.nav-link {
  /* color: #000 !important; */
  font-size: 0.9rem;
}

.logout-btn {
  background-color: transparent !important;
  color: #000;
}

.checkout-link {
  border: var(--border-black);
  border-radius: 30px;
  padding: 0.1rem 1rem;
  width: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout-link-log {
  padding: 0.1rem 1rem;
}

.h-dark .checkout-link {
  border: 1px solid #fff;
}

/* Header end */

/* Home Page */
/* section 1 */

.sec-1 {
  padding: 2rem;
  background-color: rgba(230, 230, 230, 0.5);
}

.sec-1-heading {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1.5em;
}

.sec-recent-order {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

/* section - 2 */
.sec-2 {
  margin-top: 2rem;
  padding: 2rem;
  /* text-align: center; */
  /* display: flex; */
  /* align-items: center; */
  /* flex-direction: column; */
  /* margin: auto; */
}

/* .sec-2-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 2rem;
  margin-bottom: 1rem;
}

.sec-2-featured-title {
  font-size: 1rem;
}

.sec-2-view {
  font-size: 0.7rem;
  color: #000 !important;
  font-weight: 500;
} */

.featured-prod-cont {
  padding: 0rem 2rem;
}

.featured-prod {
  background-color: #fff;
}

.featured-prod-images {
  display: flex;
  flex-direction: column;
}

.featured-prod-image-info {
  margin-bottom: 1rem;
}

.featured-prod-image-info p {
  text-align: center;
  font-size: 0.8rem;
}

.featured-prod-images {
  /* width: 6rem;
  height: 6rem; */
}

.featured-prod-images img {
  padding: 1rem;
}

.featured-prod-col1 {
  display: flex;
  border: 1px solid #e1e3e4;
}

.featured-prod-col2 {
  display: flex;
  width: 100%;
  border: 1px solid #e1e3e4;
}

.featured-prod-rank {
  background-color: #084e8a;
  width: fit-content;
}

.featured-prod-ranking {
  font-size: 0.7rem;
  padding: 0.2rem 0.7rem;
  color: #fff !important;
}

.featured-prod-name-cont {
  width: 86%;
}

.featured-prod-save {
  background-color: #ee0000;
  width: fit-content;
  border-radius: 0.2rem;
  margin-top: 1rem;
}

.featured-prod-warr {
  margin-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e1e3e4;
}

.social-icon {
  background-color: #4d4d4d;
  margin: 0rem 0.3rem;
  padding: 0.3rem;
  width: 1.5rem;
  height: 1.5rem;
  color: #fff;
  border-radius: 50%;
}

.featured-prod-warr p {
  font-size: 0.8rem;
  color: #000 !important;
  font-weight: 600;
}

.featured-prod-saving {
  font-size: 0.6rem;
  padding: 0.2rem 0.6rem;
  color: #fff !important;
}

.featured-prod-price {
  display: flex;
  margin-top: 1.5rem;
  align-items: center;
}

.featured-prod-qty {
  display: flex;
  margin-top: 2rem;
  align-items: center;
}

.featured-prod-btnCont {
  margin-top: 2rem;
  display: flex;
}

.featured-prod-btn {
  width: 100%;
  margin-right: 0.4rem;
}

.featured-prod-qty-title {
  color: #000 !important;
}

.qty-inc {
  margin-left: 0.5rem;
}

.featured-prod-price-title {
  color: #000 !important;
}

.featured-prod-price-new {
  padding: 0rem 1rem;
  color: #ee0000 !important;
  font-size: 1.3rem;
}

.featured-prod-price-old {
  color: #000 !important;
  font-size: 0.8rem;
  text-decoration: line-through;
}

.featured-prod-name {
  font-size: 1.3rem;
  color: #000 !important;
}

.featured-prod-right {
  padding: 1rem;
  width: 100%;
}

.featured-prod-image img {
  width: 28rem;
  height: 28rem;
  padding: 1rem 2rem;
}

.featured-prod-imgs img {
}

.sec-2 h5 {
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: inline-block;
  border-bottom: 2px solid var(--color-grey-light);
}

.sec-2 p {
  color: #8c8b8b;
}

.footer-div-home {
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
}

.footer-div {
  /* margin-left: 0.6rem; */
  padding: 1.2rem 5rem;
}

.footer-div img {
  margin-left: -0.6rem;
}

.address-txt b {
  font-size: 1.2rem;
}

.address-txt p {
  font-size: 0.75rem;
  line-height: 1.3rem;
}

.footer-icons {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.icons {
  display: flex;
  font-size: 1.5rem;
}

.options-txt {
  cursor: pointer;
  margin-top: 1.5rem;
  font-size: 0.75rem;
}

.options-txt p {
  color: #707070;
  padding: 0.2rem 0rem;
}
.options-txt p:hover,
.options-txt p:focus {
  transition: all 300ms ease-in-out;
  color: #000 !important;
}

.breadcrumb-link {
  color: #707070;
}

.breadcrumb-link:hover,
.breadcrumb-link:focus {
  color: #000;
  transition: all 300ms ease-in-out;
}

.heading-txt {
  font-weight: bold;
}

.icons span {
  padding: 0rem 0.8rem;
}

.address {
  margin-top: 1rem;
}

.footer-icons p {
  width: 6rem;
}

.footer-div-home p {
  text-align: center;
  width: 50%;
  margin: auto;
  margin-top: 2rem;
}

.footer-divider-home {
  margin-top: 2rem;
  border: 2px solid white;
}

.rights-footer-home p {
  margin-top: 0 !important;
}

.p-bold,
p.tab-desc {
  text-align: justify;
  font-weight: 320;
}

/* Home Page end */
/* breadcrum */
.breadcrum {
  text-transform: capitalize;
  background-color: #f7f7f7;
  padding: 1rem;
  text-align: center;
  position: fixed;
  width: 100%;
  top: 61px;
  z-index: 2;
}

.breadcrum span {
  /* font-size: 1.2rem; */
  margin-left: 0.5rem;
  font-weight: 500;
  /* word-spacing: 1rem !important; */
  /* padding-right: 0.5rem; */
  /* padding-right: 2rem; */
}

.breadcrum a {
  text-decoration: none;
  color: #575757;
}

/* breadcrum end */

/* Product Details Start */
.product-detail {
  margin-bottom: 1rem;
}

.product-detail h6 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 1.0368px;
  text-transform: uppercase;
}

.product-detail p {
  color: #194719;
}

.review-tab {
  display: flex;
  flex-direction: column;
}

.review-heading {
  margin-bottom: 2rem;
}

.review-data {
  height: 15rem;
  overflow-y: scroll;
}

.star {
  margin-bottom: 0.4rem;
  margin-right: 0.4rem;
}

.star-on {
  color: yellow;
}

.star-of {
  color: wheat;
}

.review-data-container {
  padding: 0.8rem 2rem;
  margin: 1rem 0rem;
  /* padding-bottom: 1rem; */
  border: 1px solid #ececec;
}

.review-desc {
  background-color: #f7f7f7;
  width: 40%;
  padding: 0.5rem 0rem;
}

.review-date {
  font-style: italic;
  opacity: 0.8;
  font-size: 0.86rem;
  padding-bottom: 0.5rem;
}

.icon {
  width: 1rem;
  margin-left: 0.5rem;
}

/* Product Details End */
/* Shop Page Start */
.shop-sec-1 {
  width: 100%;
  display: flex;
  align-items: center;

  justify-content: space-between;
  padding-top: 1rem;
  gap: 2rem;
  margin-top: 3rem;
}

.filter-results {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-form {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.search-icon:hover {
  transition: all 300ms ease-in-out;
  background-color: #ced4da;
}

.search-icon {
  cursor: pointer;
}

.search-icon svg {
  width: 1rem;
}

.select-box label {
  font-weight: 500;
  margin-right: 0.5rem;
}

.category-down {
  width: 1rem;
}

.toggle-category {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.drop-arrow svg {
  margin-left: 0.5rem;
}

/* Shop Page End */
.card-img-top {
  width: 100%;
  border: none;
  border-radius: 0;
}

.cart-items-len {
  display: flex;
  flex-direction: column;
}

.cart-alert-box {
  margin-top: 1rem;
}

.alert-box-content {
  display: flex;
  align-items: center;
  padding: 0rem 0.5rem;
}

.cart-item {
  margin-top: 1rem;
  z-index: 2;
}

.cart-item-img {
  width: 5rem;
  height: 5rem;
}

.overlay-cart {
  width: 100%;
  /* height: 10rem; */
  /* top: 0px; */
  background-color: transparent;
  /* position: fixed; */
}

.overlay-cart .cart-prod-name {
  opacity: 0.5;
}

.overlay-cart .cart-prod-name:hover,
.cart-prod-cate:hover {
  text-decoration: none;
  cursor: default;
}

.overlay-cart .cart-prod-cate {
  opacity: 0.5;
}

.overlay-cart .cart-prod-price {
  opacity: 0.5;
}

.prod-install-date {
  font-size: 0.8rem;
  opacity: 0.8;
  margin-top: 1rem;
  width: 100% !important;
}

.cart-prod-name {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
}

.cart-prod-price {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0.5rem;
}

.cart-prod-name:hover {
  cursor: pointer;
  text-decoration: underline;
  transition: all 300ms ease-in-out;
  opacity: 0.7;
}

.cart-item .card-img-top {
  width: 100px;
}
.h-heading {
  display: inline-block;
  font-size: 1.25rem;
  font-weight: 700;
  padding: 0 10px 7.5px 10px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.products-container {
  /* display: flex; */
  /* width: 100%; */
  /* align-items: center; */
}

.prod-card {
  margin-bottom: 1rem;
}

.related-product,
.products-container {
  cursor: pointer;
  margin: 0rem 1.5rem;
}

.related-product:hover,
.products-container:hover {
  transition: all 300ms ease-in-out;
  opacity: 0.7;
}

.card {
  width: fit-content;
  border: none;
  /* border-radius: 0; */
  box-shadow: 0 1px 3px -2px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  padding: 0;
}

.category-img-box {
  position: relative;
  display: inline-block;
}

.category-img img {
  /* height: 15rem; */
  /* width: 20rem !important; */
  height: 17rem !important;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  transition: opacity 0.6s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.6s;
  pointer-events: none;
}

.category-footer {
  padding: 0.7em 10px 1.4em 10px;
  text-transform: uppercase;
  text-align: center;
  background-color: #fff !important;
}

.absolute-footer.dark {
  color: rgba(255, 255, 255, 0.5);
}

.absolute-footer {
  background-color: #333;
  color: rgba(0, 0, 0, 0.5);
  padding: 10px 0 15px;
  font-size: 0.9em;
}

.shop-top-img {
  position: relative;
  border: 3px solid #ececec !important;
  border-radius: 0.2rem;
}

.shop-top-img img {
  height: 18rem;
  /* object-fit: contain; */
}

.no-recent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-recent-conta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  /* font-size: 0.8rem; */
  padding: 1.6rem;
  border: 1px solid #b5b5b5;
  border-radius: 0.6rem;
}

.no-recent-conta button {
  /* font-size: 0.7rem; */
  margin-top: 1rem;
}

.no-recent-btn {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.top-right {
  z-index: 1;
  position: absolute;
  top: 10px;
  /* right: 100px; */
  /* width: ; */
}

.sub-images {
  cursor: pointer;
  padding: 1rem;
}

.sub-images img {
  height: 5rem;
  width: 5rem;
}

.top-right span {
  color: #fff;
  font-size: 0.5rem;
  display: block;
  padding: 2px 5px;
  border-radius: 5px;
}

.top-right span:first-child {
  background-color: #f96ffd;
  margin-bottom: 3px;
}

.top-right span:last-child {
  background-color: #aa4bff;
}

.p-mb-1 {
  margin-bottom: 1px;
}

.side-link {
  list-style: none;
  padding: 5px 0;
  border-top: 1px solid #ececec;
  font-size: 1rem;
  font-weight: 500;
  color: black;
  cursor: pointer;
}

.card-img {
  width: 100% !important;
}

.side-link-subCategory {
  cursor: pointer;
  list-style: none;
  padding: 5px 0;
  margin-left: 1.2rem;
  color: #8c8b8b;
}

/* product details start */
.product-content {
  padding: 2rem 0;
}

.sub-head-1 {
  color: rgba(102, 102, 102, 0.7);
  font-weight: normal;
}

.sub-head-1 span {
  color: #000;
}

.divider {
  height: 3px;
  display: block;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 1em 0 1em;
  width: 100%;
  max-width: 30px;
}

.price {
  font-size: 2em;
  margin: 0.5em 0;
  font-weight: 500;
  line-height: 1;
}

.prod-stock-info p {
  font-weight: 500;
  font-size: 0.8em;
}

.prod-select-date {
  margin-top: 2rem;
}

.select-date-label {
  color: #474d66;
  font-size: 0.9rem;
}

.tab-desc p {
  padding: 1rem 0rem;
}

.prod-cont-info {
  display: flex;
}

.prod-btn-box {
  margin-top: 3rem;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  border-bottom: var(--border-thin-light);
  padding-bottom: 2rem;
}

::-webkit-scrollbar {
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.installers-div {
  display: flex;
  /* justify-content: space-between; */
  overflow-x: auto;
}

.installer-cont {
  margin: 0.3rem 1rem !important;
  display: flex;
  flex-direction: column;
  border: 1px solid #e9e7e2;
  border-radius: 0.6rem;
  padding: 1rem;
}

.installer-img {
  display: flex;
  align-items: center;
}

.installer-img img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.installer-location-info {
  margin-top: 1rem;
}

.installer-location-info p {
  font-size: 0.8rem;
  font-weight: 400;
}

.installer-name {
  font-weight: 500;
  font-size: 0.9rem;
}

.installer-ID {
  font-size: 0.7rem;
  font-weight: 400;
}

.user-pin {
  font-size: 0.8rem;
  color: #8f8f93;
}

.prod-btn-box-1 {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: var(--border-thin-light);
  max-width: 6rem;
  width: min-content;
  border-radius: 3px;
  font-weight: 600;
}

.prod-btn-box-1 > div {
  text-align: center;
  font-size: 1.2rem;
  padding: 0 0.5rem;
  background-color: #f9f9f9;
  height: 100%;
}

.prod-btn-box-1 > span {
  border-left: var(--border-thin-light);
  border-right: var(--border-thin-light);
  padding: 0.32rem 0.8rem;
  height: 100%;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.btn-dark {
  background: #000000;
  border: 1.67333px solid rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.proceed-btn.btn-dark {
  width: 100%;
}

.diasbled-proceed {
  cursor: not-allowed;
}

/* .btn-dark {
  color: #000;
  background-color: transparent;
  border: var(--border-thin-light);
}
.btn-dark:hover {
  color: #000;
  background-color: transparent;
  border: var(--border-thin-light);
} */

.h-dark .log-btn {
  color: yellowgreen !important;
}

.h-dark .log-btn:hover {
  color: yellowgreen !important;
}

/* .h-dark .log-btn:hover,
.log-btn:focus,
.log-btn:active {
  color: white !important;
} */

/* shop product end */

/* Cart */
.cart-item {
  padding: 1.5rem 1rem;
  border-bottom: var(--border-thin-light);
}

.cart-prod-cate:hover {
  transition: all 300ms ease-in-out;
  text-decoration: underline;
  opacity: 0.7;
  cursor: pointer;
}

.btn-box {
  position: relative;
}

.close-btn {
  position: absolute;
  top: -10px;
  right: -20px;
}

.cart-detail-table {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: var(--border-thin-dark);
}

.cart-detail-table tr > td:nth-child(2) {
  width: 50px;
}

.update-btn {
  margin: 40px 0;
  /* background-color: #fff; */
  border: var(--border-black);
  width: 100%;
  /* color: #000; */
}

.coupon-sec {
  margin: 2rem 0;
  display: flex;
  align-items: center;
}

.p-divider {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
}

/* Checkout Page Start */
.checkout-sec-1 p > span {
  font-size: small;
  color: #707070;
}

.checkout-sec-1-1 {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 5px;
  width: max-content;
  border: var(--border-thin-dark);
}

.checkout-sec-2 {
  padding: 2rem 0;
}

.order-sec {
  /* height: 500px; */
  background-color: #00000005;
  box-shadow: 1.80976px 1.80976px 5.42929px rgba(0, 0, 0, 0.2),
    0px 1.80976px 0px rgba(0, 0, 0, 0.07),
    inset 0px 0px 0px 1.80976px rgba(0, 0, 0, 0.05);
  /* background-color: rgba(0, 0, 0, 0.1); */
  margin: auto;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 60% !important;
  /* margin-left: 5rem; */
}

.order-heading {
  width: 100%;
  padding-top: 0.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
}

.order-heading p {
  font-size: 0.9rem;
  font-weight: 600;
}

.order-product-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

hr {
  margin: 0.5rem 0rem;
}

.heading-hr {
  /* color: #505050; */
  border-top: 1px dotted #8c8b8b;
  border-bottom: 1px dotted #2e2e2e;
}

.order-hr {
  border-top: 1px dotted #8c8b8b;
  border-bottom: 1px dotted #b5b5b5;
}

.order-product-name {
  font-size: 0.9rem;
  width: 70%;
}

.shipping-txt {
  padding: 0.5rem 0rem;
  color: #8c8b8b;
  font-size: 0.8rem;
  font-weight: 500;
}

.shipping-disc {
  display: flex;
}

.order-total-txt,
.order-total-amt {
  font-weight: 600;
}

.shipping-disc p {
  font-size: 0.8rem;
}

.radio-btn {
  font-size: 0.8rem;
}

.order-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-coupon-cont {
  margin-top: 0.6rem;
}

.order-coupon {
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  justify-content: space-between;
  background-color: #94949433;
  padding: 0.8rem 0.5rem;
}

.order-coupon-txt {
  font-size: 0.8rem;
  font-weight: 600;
}

.order-coupon-amt p {
  font-size: 0.8rem;
  display: flex;
  justify-content: flex-end;
}

.order-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem 0rem;
}

.order-bottom-txt {
  font-size: 0.84rem;
  margin: 0.5rem 0rem;
  padding: 0;
}

.order-sec h5 {
  text-transform: uppercase;
}

.order-confirmation-box {
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0rem;
}

.order-right-sec {
  padding: 1.2rem 0.8rem;
  width: 100%;
  border-radius: 0.2rem;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
}

.order-left-sec {
  /* width: 500px; */
  /* display: flex; */
  /* flex-direction: column; */
}

.order-details li {
  margin: 1rem 0rem;
}

.order-details-heading {
  padding-top: 0.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.billing-address-container {
  margin: 1.5rem 0rem;
  font-style: italic;
  font-size: 1.1rem;
}

.order-alert-box-content {
  display: flex;
  align-items: center;
  margin: auto;
  width: 50%;
  margin-bottom: 1.5rem;
}

.order-conf-details {
  margin-top: 1rem;
}

.order-conf-details p,
ul {
  font-size: 1.1rem;
  font-weight: 500;
}

.order-conf {
  padding: 2rem;
  display: flex;
  /* flex-direction: column; */
  /* flex-direction: column-reverse; */
  box-shadow: 1.80976px 1.80976px 5.42929px rgba(0, 0, 0, 0.2),
    0px 1.80976px 0px rgba(0, 0, 0, 0.07),
    inset 0px 0px 0px 1.80976px rgba(0, 0, 0, 0.05);
  align-items: center;
  justify-content: space-between;
}

.order-conf-cont {
  flex: 0.9;
}

.order-conf-image img {
  width: 25rem;
  height: 25rem;
}

.billing-form .form-control {
  border: none;
  outline: none;
  border-bottom: 1px solid #ced4da;
  border-radius: 0;
}

/* Checkout Page End */

/* SignIn SingUp Page Start */
.toggle-link-item {
  text-decoration: none;
  font-weight: 700;
  color: #575757;
  font-size: large;
  padding: 0 1rem;
}

.toggle-link-item:first-child {
  border-right: 2px solid #575757;
}

.toggle-link-item.active-link,
.toggle-link-item:hover {
  color: #aa4bff;
}

.form-box {
  width: 100%;
  display: inline-block;
  border: var(--border-thin-dark);
  border-radius: 5px;
  padding: 2rem;
}

/* SignIn SingUp Page End */

/* Profile Page */
.content-box {
  width: 50% !important;
  border: var(--border-thin-dark);
  border-radius: 5px;
  margin-bottom: 1rem;
}

.content-header {
  border-bottom: var(--border-thin-dark);
  padding: 1rem;
}

.content-header h5 {
  text-transform: uppercase;
}

.content-header h5 > span {
  margin-right: 1rem;
}

.content-body {
  padding: 1rem;
}

.content-body .content-body-top {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: var(--border-thin-dark);
}

.content-body .content-body-top h6 {
  text-transform: uppercase;
}

.form-sub-sec {
  display: flex;
  justify-content: end;
  padding: 1rem 0;
}

.add-address-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.border-thin {
  border: var(--border-thin-dark);
}

.edit-btn.btn-primary-dark {
  background-color: #f3502c;
}

/* How to section Start */
.number-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 50%;
  border: 1px solid #194719;
}

.add-review {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 1rem 0rem;
  margin-bottom: 2rem;
}

.add-review button {
  font-size: 0.8rem;
}

.product-review-cont {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 2rem;
}

.product-review-img {
  width: 8rem;
}

.product-review-desc {
  margin-left: 1rem;
}

.add-review-cont {
  display: flex;
  flex-direction: column;
}

.overall-star-div {
  margin: 1rem 0rem;
}

.overall-star {
  margin-top: 0.6rem;
}

.overall-star .react-simple-star-rating svg {
  width: 35px;
  height: 35px;
}

.add-title {
  margin: 1rem 0rem;
}

.form-input {
  margin: 1rem 0rem;
}

.form-btn {
  margin-top: 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.product-rating {
  display: flex;
  /* background-color: yellow; */
  width: fit-content;
  align-items: center;
  justify-content: space-between;
}

.product-rating .react-simple-star-rating svg {
  width: 30px;
  height: 30px;
}

.no-prod-link {
  text-decoration: underline;
}

.no-prod-link span {
  padding-top: 2rem !important;
}

.no-prod-link:hover {
  opacity: 0.7;
  transition: all 300ms ease-in-out;
}

.about_edwin {
  display: flex;
  align-items: center;
}

.aboutus-top-img img {
  width: 100%;
}

.about-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-img img {
  width: 15rem;
  height: 15rem;
}

.about-edwin-info {
  margin-top: 1rem;
}

.about-edwin-info p {
  font-weight: 500;
  margin-top: 0.5rem;
}

.about-edwin-heading {
  font-weight: 700;
  font-size: 2rem;
}

.locations-cont {
  margin-top: 5rem;
}

.location-cont {
  margin-top: 3rem;
}

.locations-info {
  margin-top: 1rem;
  font-size: 0.9rem;
}

.aboutus-map-img img {
  height: 20rem;
}

.locations-details {
  font-size: 1.2rem;
  opacity: 0.8;
  padding: 0.7rem;
}

.location-div {
  margin: 1rem 0rem;
  display: flex;
  flex-direction: column;
  border: 0.5px solid #bbbbbb;
}

.view-map {
  font-size: 0.9rem;
  padding: 1rem;
}

.contactus-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.order-install-date,
.order-new-install-date {
  font-size: 0.8rem;
  margin: 0.6rem 0rem;
  opacity: 0.8;
}

.order-new-install-date {
  font-weight: 500;
}

.contactus-upper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1rem;
}

.contactus-form {
  margin-top: 2rem;
  width: 70%;
}

.contactus-upper-title {
  font-size: 1.8rem;
  font-weight: 600;
}

.all-orders-prod-name:hover {
  cursor: pointer;
  transition: all 300ms ease-in-out;
  opacity: 0.7;
}

.featured-prod-image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.featured-prod-image img {
  width: 20rem;
  height: 20rem;
}

.featured-prod-imgs {
  display: flex;
  overflow-x: auto;
  width: 80%;
}

.view-order p {
  font-size: 0.8rem;
  text-decoration: underline;
  margin: 0.6rem 0rem;
}

.view-order:hover {
  opacity: 0.7;
  transition: all 300ms ease-in-out;
  color: #000;
}

.cart-prod-remove-btn {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 0.9rem 0rem;
  cursor: pointer;
}

.cart-icon-delete {
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  padding: 0.2rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.cart-icon-delete:hover {
  background-color: red;
  color: white;
  transition: 250ms all ease-in-out;
}

/* .carousel-img {
  width: 100%;
  height: 100%;
} */

/* How to section end */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .card-img-top {
    width: 300px;
  }
  .order-sec {
    width: 300px;
  }
  .order-conf {
    /* flex-direction: column-reverse; */
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .carousel-img {
    height: 500px;
  }
  .p-divider {
    width: 300px;
  }
  .form-box {
    width: 50%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .form-box {
    width: 40%;
  }
}

.h-dark .navbar-toggler-icon {
  background-image: url("../public/images/toggle/toggle-light.svg");
}

.h-light .navbar-toggler-icon {
  background-image: url("../public/images/toggle/toggle-dark.svg");
}

@media (max-width: 576px) {
  .not-allowed-container {
    width: 100%;
  }

  .not-allowed-div {
    width: 100%;
  }

  .not-allowed-heading {
    flex-direction: column;
    align-items: flex-start;
  }

  .not-allowed-heading p {
    font-size: 2rem;
  }

  .err-code {
    font-size: 0.7rem;
  }

  .not-allowed-body {
    font-size: 0.8rem;
    margin-top: 1rem;
  }

  .shop-top-img {
    width: fit-content;
  }

  .shop-top-img img {
    width: 16rem;
  }

  .product-detail {
    display: flex;
    flex-direction: column;

    align-items: flex-start;
    justify-content: flex-start;
    width: 16rem;
  }

  .product-detail h6 {
    width: 10rem;
  }

  .sub-head-1 {
    font-size: 0.8rem;
  }

  .sub-head-2 {
    margin-top: 1rem;
    font-size: 1rem;
  }

  .warr-txt {
    font-size: 0.7rem;
  }

  .price {
    font-size: 1.3rem;
  }

  .prod-cont-num,
  .prod-cont-txt {
    font-size: 0.8rem;
  }

  .prod-select-date {
    margin-top: 1.5rem;
  }

  .select-date-label {
    color: #474d66;
    font-size: 0.7rem;
  }

  .prod-footer p {
    font-size: 0.8rem;
  }

  .tab-desc p {
    font-size: 0.8rem;
    padding: 0.5rem 0rem;
  }

  .footer-icons {
    margin-top: 1rem;
    padding: 0rem;
  }

  .heading-txt {
    margin-top: 1rem;
  }

  .options-txt {
    margin-top: 0.6rem;
    margin-bottom: 1rem;
  }

  .result-length {
    font-size: 0.7rem;
  }

  .filters-txt {
    font-size: 1.5rem;
  }

  .categories-txt {
    font-size: 0.9rem;
  }

  .side-link {
    font-size: 0.8rem;
  }

  .side-link-subCategory {
    font-size: 0.7rem;
  }

  .related-products-txt {
    font-size: 1rem;
    font-weight: bold;
  }

  .footer-div {
    padding: 1.2rem 3rem;
    /* margin-left: 0.6rem; */
  }

  .footer-icons {
    width: 90%;
  }

  .icons {
    /* text-align: center; */
    font-size: 1.2rem;
  }

  .address-txt b {
    font-size: 1rem;
  }

  .heading-txt {
    font-size: 0.9rem;
  }

  .rights-footer {
    font-size: 0.8rem;
  }

  .content-box {
    width: 100% !important;
  }

  .content-box h5 {
    font-size: 1rem;
  }

  .content-body-top,
  .content-body-top h6 {
    font-size: 0.8rem;
  }

  .product-content h1 {
    text-align: center;
    padding: 2rem 0rem;
  }

  .coupon-code-box {
    padding: 0rem 1rem;
  }

  .coupon-sec button {
    font-size: 0.8rem;
  }

  .breadcrum {
    top: 53px;
    padding: 0.5rem;
  }

  .no-recent-conta {
    padding: 1rem;
    font-size: 0.7rem;
  }

  .no-recent-conta button {
    font-size: 0.7rem;
  }

  .filter-order {
    padding: 0rem 1rem;
  }

  .form-select-filter {
    width: 5rem;
    font-size: 0.8rem;
  }

  .sub-head-2 {
    text-align: left !important;
  }

  .all-orders-at {
    flex-direction: column;
    align-items: flex-start;
  }

  .order-pending,
  .order-paid,
  .order-delivered {
    margin-top: 0.5rem;
    font-size: 0.83rem;
  }

  .re-order-btn {
    padding: 0rem 2rem;
  }

  .re-order-btn-all-order button {
    font-size: 0.8rem;
    margin-top: 2rem;
  }

  .all-orders-prod {
    padding: 0rem 1rem;
    align-items: center;
  }

  .all-orders-prod-desc {
    width: 100% !important;
  }

  .order-install-date,
  .order-new-install-date {
    font-size: 0.65rem;
  }

  .all-orders-prod-name {
    font-size: 0.9rem;
  }

  .order-id {
    text-align: left;
    padding-right: 0rem;
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    font-size: 0.8rem;
  }

  .shop-sec-1 {
    /* width: 100%; */
    flex-direction: column-reverse;
    justify-content: space-between;
  }

  .filter-results {
    width: 100%;
  }

  .review-desc {
    width: 100%;
  }

  .review-title {
    font-size: 0.86rem;
  }
  .products-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .add-review {
    justify-content: flex-start;
  }

  .add-review button {
    font-size: 0.65rem;
  }

  .product-review-cont {
    padding: 0rem 1rem;
    font-size: 0.8rem;
    flex-direction: column;
    align-items: flex-start;
  }

  .product-review-desc {
    margin: 0rem;
    margin-top: 1rem;
  }

  .overall-star .react-simple-star-rating svg {
    width: 20px;
    height: 20px;
  }

  .form-input {
    font-size: 0.9rem;
  }

  .rating-tooltip {
    font-size: 0.8rem;
  }

  .breadcrum span {
    font-size: 0.8rem;
    margin-left: 0.3rem;
  }

  .product-rating .react-simple-star-rating svg {
    width: 20px;
    height: 20px;
  }

  .not-found-text2 {
    font-size: 7rem !important;
  }

  .go-to-btn {
    margin: 7rem 0rem;
  }

  .no-prod-msg-box span {
    font-size: 1rem !important;
  }

  .no-prod-link {
    font-size: 0.8;
  }
  .no-prod-msg-box-alert {
    width: 90% !important;
  }

  .order-alert-box-content {
    width: 90% !important;
    font-size: 0.8rem;
  }

  .order-alert-box-content h3 {
    font-size: 1rem !important;
  }

  .order-left-sec {
    width: 100%;
    font-size: 0.85rem;
  }

  .order-confirmation-box {
    width: 100%;
    font-size: 0.8rem;
  }

  .order-details {
    font-size: 0.8rem;
  }

  .billing-addr {
    font-size: 0.8rem;
  }

  .billing-address-container {
    font-size: 0.8rem;
  }

  .cart-prod-name {
    font-size: 0.6rem;
    margin: 0.6rem 0rem;
  }

  .cart-prod-cate {
    font-size: 0.75rem;
  }

  .cart-prod-price {
    font-size: 0.7rem;
    margin-top: 0.3rem;
  }

  .prod-install-date {
    font-size: 0.6rem;
    margin: 0.8rem 0rem;
  }

  .order-conf {
    padding: 1.5rem;
    flex-direction: column;
  }

  .order-conf-image img {
    width: 20rem;
    height: 20rem;
    margin-bottom: 1rem;
  }

  .order-conf-details p,
  ul {
    font-size: 0.8rem;
    font-weight: 500;
  }

  .featured-prod-images img {
    width: 5rem;
    height: 5rem;
  }

  .featured-prod-images {
    display: flex;
  }

  .featured-prod-name {
    font-size: 0.9rem;
  }

  .social-icon {
    width: 1.3rem;
    height: 1.3rem;
  }

  .featured-prod-warr p {
    font-size: 0.6rem;
  }

  .featured-prod-price-title {
    font-size: 0.9rem;
  }

  .featured-prod-price-new {
    font-size: 1rem;
  }

  .featured-prod-price-old {
    font-size: 0.6rem;
  }

  .featured-prod-qty-title {
    font-size: 0.9rem;
  }

  .featured-prod-btn {
    font-size: 0.8rem;
  }

  /* .featured-prod-image img {
    width: 10rem !important;
    height: 10rem !important;
  } */

  .shop-cate-home {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .aboutus-top-img {
    width: 100%;
  }

  .about-img {
    margin-top: 1rem;
  }

  .about-edwin-heading {
    font-size: 1rem;
  }

  .about-edwin-info {
    font-size: 0.8rem;
  }

  .about-img img {
    width: 10rem;
    height: 10rem;
  }

  .aboutus-map-img img {
    width: 15rem;
    height: 12rem;
  }

  .locations-info {
    font-size: 0.7rem;
  }

  .location-cont {
    margin-top: 1rem;
  }

  .locations-details {
    font-size: 1rem;
  }

  .view-map {
    font-size: 0.7rem;
  }

  .installer-cont {
    margin-right: 0.7rem !important;
  }

  .add-address-btn button {
    font-size: 0.7rem;
  }

  .all-addr-empty {
    font-size: 0.8rem;
  }

  .all-addr-title {
    font-size: 1rem;
  }

  .prod-btn-box-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .prod-detail-err-info {
    display: block;
    font-size: 0.8rem;
  }

  .prod-btn-box {
    flex-direction: column;
    align-items: flex-start;
  }

  .btn-box {
    margin-top: 1rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .btn-box {
    margin-top: 1rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .carousel-product-name {
    font-size: 1.2rem;
  }

  .installer-cont {
    margin-right: 0.9rem !important;
  }

  .order-install-date,
  .order-new-install-date {
    font-size: 0.7rem;
  }

  .carousel-product-price {
    font-size: 1rem;
  }

  .carousel-product-updated {
    font-size: 0.8rem;
  }

  .shop-sec-1 {
    /* width: 100%; */
    flex-direction: column-reverse;
    justify-content: space-between;
  }

  .filter-results {
    width: 100%;
  }

  .options-txt {
    margin-top: 0.7rem;
    margin-bottom: 1rem;
  }

  .breadcrum {
    top: 54px;
  }

  .form-select-filter {
    width: 6rem;
    font-size: 0.8rem;
  }

  .shop-top-img {
    width: fit-content;
  }

  .shop-top-img img {
    width: 16rem;
  }
  .review-desc {
    width: 100%;
  }

  .not-found-text2 {
    font-size: 8rem !important;
  }

  .go-to-btn {
    margin: 10rem 0rem;
  }

  .no-prod-msg-box-alert {
    width: 100% !important;
  }

  .order-left-sec {
    width: 90%;
    font-size: 0.85rem;
  }

  .order-confirmation-box {
    width: 90%;
    font-size: 0.8rem;
  }

  .order-details {
    font-size: 0.9rem;
  }

  .billing-addr {
    font-size: 0.9rem;
  }

  .billing-address-container {
    font-size: 0.9rem;
  }

  .order-alert-box-content {
    width: 100% !important;
    font-size: 0.9rem;
  }

  .order-conf {
    padding: 1.5rem;
    flex-direction: column;
  }

  .order-conf-image img {
    width: 22rem;
    height: 22rem;
    margin-bottom: 1rem;
  }

  .order-conf-details p,
  ul {
    font-size: 0.9rem;
    font-weight: 500;
  }

  .cart-prod-name {
    font-size: 0.7rem;
    /* margin: 0.6rem 0rem; */
  }

  .cart-prod-cate {
    font-size: 0.65rem;
  }

  .aboutus-map-img img {
    /* width: 15rem; */
    height: 15rem;
  }

  .prod-btn-box-2 {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }

  .prod-detail-err-info {
    display: block;
    font-size: 0.8rem;
  }

  .prod-btn-box {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-link {
    font-size: 0.75rem !important;
    padding: 0.4rem !important;
  }

  .checkout-link {
    width: 5rem;
    padding: 0.1rem 0.6rem;
  }

  .brand-img {
    width: 4rem;
  }

  .menu-languages button {
    font-size: 0.6rem !important;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .category-img img {
    width: 18rem !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .order-left-sec {
    width: 90%;
    /* font-size: 0.85rem; */
  }

  .order-confirmation-box {
    width: 90%;
    /* font-size: 0.8rem; */
  }

  .order-conf-image img {
    width: 20rem;
    height: 20rem;
    margin-right: 1rem;
  }
}
